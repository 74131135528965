import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { css } from "@emotion/core";

import {
  FullPageOrangeSection,
  FullPageWhiteSection,
  BlurbWrapper,
  BlurbText,
  BlurbLink,
  GreenBreak,
  PageBreakText,
  OrangeBreak,
  TextImageWrapper,
  TitleText,
} from "../components/styled-components";
import Hero from "../components/hero";
import HexTransition from "../components/hex-transition";
import MainLayout from "../components/main-layout";
import QuickLinks from "../components/quick-links";
import ImageWrapper from "../components/image-wrapper";
import LargeIcon from "../components/large-icon";

const pathArray = [
  {
    path: "/consulting#acquisition",
    title: "SITE AQUISITION",
  },
  {
    path: "/consulting#development",
    title: "PROJECT DEVELOPMENT",
  },
  {
    path: "/consulting#management",
    title: "PROJECT MANAGMENT",
  },
];

const Consulting = ({ data }) => {
  const consultingOne = data.allContentfulImage.nodes.find(
    (node) => node.slug === "consulting-1"
  );
  const consultingThree = data.allContentfulImage.nodes.find(
    (node) => node.slug === "consulting-3"
  );
  return (
    <MainLayout>
      <Helmet>
        <title>BOW - Consulting</title>
        <meta
          name="description"
          content="Whether you need assistance in Site Acquisition, Project Development or Project Management – or all three – leave the heavy-lifting to us, so you can focus on running and managing your portfolio. Contact us today and get ahead of your competition with confidence."
        />
      </Helmet>
      <FullPageOrangeSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <Hero
            fluid={data.contentfulHeroImage.heroImage.fluid}
            alt={data.contentfulHeroImage.alt}
            type={"WtoO"}
          />
          <QuickLinks pathArray={pathArray} />
          <HexTransition type={"GtoW"} />
          <br />
          <TitleText color="green">CONSULTING</TitleText>
        </div>
        <br />
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            Whether you need assistance in{" "}
            <BlurbLink to="/consulting#acquisition" color="#215732" stripHash>
              Site Acquisition
            </BlurbLink>
            ,{" "}
            <BlurbLink to="/consulting#development" color="#215732" stripHash>
              Project Development
            </BlurbLink>{" "}
            or{" "}
            <BlurbLink to="/consulting#development" color="#215732" stripHash>
              Project Management
            </BlurbLink>{" "}
            – or all three – leave the heavy-lifting to us, so you can focus on
            running and managing your portfolio.{" "}
            <BlurbLink to="/about#contact" color="#215732" stripHash>
              Contact us
            </BlurbLink>{" "}
            today and get ahead of your competition with confidence.
          </BlurbText>
        </BlurbWrapper>
        <div />
        <br />
      </FullPageOrangeSection>
      <FullPageWhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <GreenBreak height={"10vh"} minHeight={"100px"} id="acquisition">
            <PageBreakText>SITE ACQUISITION</PageBreakText>
          </GreenBreak>
          <HexTransition type={"GtoW"} />
          <br />
        </div>
        <TextImageWrapper>
          <div
            data-sal="slide-left"
            css={css`
              width: 40%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper
              fluid={consultingOne.image.fluid}
              alt={consultingOne.alt}
            />
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 50%;
              display: flex;
              flex-direction: column;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <BlurbText color="#1a4428" margin={true}>
              BOW Renewables works directly with prospective land owners to
              determine and execute any and all “moving parts” to get the land
              ready for solar installation. We conduct preliminary research,
              complete all constraints mapping and evaluate interconnection
              suitability. Above all, we systematically identify and investigate
              any environmental and geographic impacts. BOW also ensures
              potential leases are the best fit for your needs, and coordinate
              with design, zoning and construction management teams.
            </BlurbText>
          </div>
        </TextImageWrapper>
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            <BlurbLink to="/about#contact" color="#215732" stripHash>
              Contact us
            </BlurbLink>{" "}
            to learn more about Site Acquisition.
          </BlurbText>
        </BlurbWrapper>
        <br />
      </FullPageWhiteSection>
      <FullPageWhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <OrangeBreak height={"10vh"} minHeight={"100px"} id="development">
            <PageBreakText color="green">PROJECT DEVELOPMENT</PageBreakText>
          </OrangeBreak>
          <HexTransition type={"OtoW"} />
          <br />
        </div>
        <TextImageWrapper reverse={true}>
          <div
            data-sal="slide-left"
            css={css`
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <BlurbText color="#1a4428" margin={true}>
              Entering into the world of clean, renewable solar energy comes
              with its own set of guidelines and checklists. These crucial steps
              are needed for the best possible solar solution, and our Project
              Development team takes care of all the details. We serve as the
              liaison between engineers, system designers and installers, and
              identify opportunities to reduce costs and minimize risks. All
              while helping to establish realistic goals and plans, and managing
              the entire public permitting process prior to Notice to Proceed
              (NTB).
            </BlurbText>
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 40%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <LargeIcon type="gant" />
          </div>
        </TextImageWrapper>
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            <BlurbLink to="/about#contact" color="#215732" stripHash>
              Contact us
            </BlurbLink>{" "}
            to learn more about Project Development.
          </BlurbText>
        </BlurbWrapper>
        <br />
      </FullPageWhiteSection>
      <FullPageWhiteSection>
        <div
          css={css`
            width: 100%;
          `}
        >
          <OrangeBreak height={"10vh"} minHeight={"100px"} id="management">
            <PageBreakText color="green">PROJECT MANAGMENT</PageBreakText>
          </OrangeBreak>
          <HexTransition type={"OtoW"} />
          <br />
        </div>
        <TextImageWrapper>
          <div
            data-sal="slide-left"
            css={css`
              width: 40%;
              display: flex;
              justify-content: center;
              @media (max-width: 768px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper
              fluid={consultingThree.image.fluid}
              alt={consultingThree.alt}
            />
          </div>
          <div
            data-sal="slide-right"
            css={css`
              width: 50%;
              display: flex;
              flex-direction: column;
              text-align: center;
              @media (max-width: 768px) {
                width: 90vw;
              }
            `}
          >
            <BlurbText color="#1a4428" margin={true}>
              Getting “boots on the ground” for your project is one thing.
              Keeping those boots moving is another. BOW Renewables Project
              Management maximizes the efficiency of your project by
              coordinating contractors, materials and processes. Juggling
              installers, technicians and vendors is a delicate balancing act,
              and our Project Management team is well-versed in every aspect. In
              the end, we’re responsible for the quality and efficacy of the
              work, and we stop at nothing to get the job done right, the first
              time.
            </BlurbText>
          </div>
        </TextImageWrapper>
        <BlurbWrapper>
          <BlurbText color="#1a4428" margin={true}>
            <BlurbLink to="/about#contact" color="#215732" stripHash>
              Contact us
            </BlurbLink>{" "}
            to learn more about Project Management.
          </BlurbText>
        </BlurbWrapper>
        <br />
      </FullPageWhiteSection>
    </MainLayout>
  );
};

export default Consulting;

export const query = graphql`
  query ConsultingQuery {
    contentfulHeroImage(slug: { eq: "consultingHero" }) {
      heroImage {
        fluid(maxWidth: 3080, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroAlt
    }
    allContentfulImage(filter: { tags: { in: "consulting" } }) {
      nodes {
        alt
        slug
        image {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
