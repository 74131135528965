import React from "react";
import Image from "gatsby-image";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";

const LargeIcon = ({ type }) => {
  const icons = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "icons" } }) {
        nodes {
          sharp: childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
          id
        }
      }
    }
  `);
  const barnIcon = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "barn-icon.png"
  );
  const bizIcon = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "building-icon.png"
  );
  const acqIcon = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "aquisition-icon.png"
  );
  const devIcon = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "development-icon.png"
  );
  const manageIcon = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "management-icon.png"
  );
  const farmingSolarIcon = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "farming-solar-icon.png"
  );
  const landLegacyIcon = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "land-legacy-icon.png"
  );
  const gant = icons.allFile.nodes.find(
    (image) => image.sharp.fluid.originalName === "gant-icon.png"
  );

  const typeMap = {
    barn: barnIcon,
    biz: bizIcon,
    acq: acqIcon,
    dev: devIcon,
    manage: manageIcon,
    farmSolar: farmingSolarIcon,
    landLegacy: landLegacyIcon,
    gant: gant,
  };

  return (
    <>
      <Image
        fadeIn={false}
        loading="eager"
        fluid={typeMap[type].sharp.fluid}
        alt={typeMap[type].alt}
        css={css`
          width: 80%;
          height: auto;
          @media (max-width: 768px) {
            margin-top: 1rem;
          }
        `}
      />
    </>
  );
};

export default LargeIcon;
