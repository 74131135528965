import React from "react";
import Image from "gatsby-image";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";

const HexTransition = ({ type, text, id }) => {
  const hexTransitions = useStaticQuery(graphql`
    query {
      otw: file(relativePath: { eq: "full-hex-orange-to-white-flat.png" }) {
        image: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gtw: file(relativePath: { eq: "full-hex-green-to-white-flat.png" }) {
        image: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const fullOrangeToWhite = hexTransitions.otw;
  const fullGreenToWhite = hexTransitions.gtw;

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: -5px;
        min-width: 1000px;
      `}
    >
      {type === "WtoO" && (
        <>
          {text && (
            <h1
              css={css`
                /* margin: 1.5rem auto; */
                margin: 2.5rem auto 1.5rem auto;
                color: #1a4428;
                font-size: 2rem;
                width: 50%;
                min-width: 500px;
                text-align: center;
                @media (max-width: 1280px) {
                  font-size: 1.75rem;
                }
                @media (max-width: 950px) {
                  font-size: 1.5rem;
                }
                @media (max-width: 768px) {
                  font-size: 1.25rem;
                  width: 90vw;
                  min-width: 100px;
                }
                @media (max-width: 425px) {
                  /* margin: 1rem auto 0 auto; */
                  font-size: 1.25rem;
                  width: 90vw;
                  min-width: 100px;
                }
              `}
              id={id}
            >
              {text}
            </h1>
          )}
        </>
      )}
      {type === "OtoW" && (
        <>
          <Image
            fadeIn={false}
            loading="eager"
            fluid={fullOrangeToWhite.image.fluid}
            alt="Transition from Orange to White featuring hexagonal pattern"
            css={css`
              width: 100%;
              margin-top: 0;
            `}
          />
          {text && (
            <h1
              css={css`
                /* margin: 1.5rem auto; */
                margin: 2.5rem auto 1.5rem auto;
                color: #1a4428;
                font-size: 2rem;
                width: 50%;
                min-width: 500px;
                text-align: center;
                @media (max-width: 1280px) {
                  font-size: 1.75rem;
                }
                @media (max-width: 950px) {
                  font-size: 1.5rem;
                }
                @media (max-width: 768px) {
                  font-size: 1.25rem;
                  width: 90vw;
                  min-width: 100px;
                }
                @media (max-width: 425px) {
                  /* margin: 1rem auto 0 auto; */
                  font-size: 1.25rem;
                  width: 90vw;
                  min-width: 100px;
                }
              `}
              id={id}
            >
              {text}
            </h1>
          )}
        </>
      )}
      {type === "GtoW" && (
        <>
          <Image
            fadeIn={false}
            loading="eager"
            fluid={fullGreenToWhite.image.fluid}
            alt="Transition from Green to White featuring hexagonal pattern"
            css={css`
              width: 100%;
              margin-top: 0;
            `}
          />
          {text && (
            <h1
              css={css`
                /* margin: 1.5rem auto; */
                margin: 2.5rem auto 1.5rem auto;
                color: #1a4428;
                font-size: 2rem;
                width: 50%;
                min-width: 500px;
                text-align: center;
                @media (max-width: 1280px) {
                  font-size: 1.75rem;
                }
                @media (max-width: 950px) {
                  font-size: 1.5rem;
                }
                @media (max-width: 768px) {
                  font-size: 1.25rem;
                  width: 90vw;
                  min-width: 100px;
                }
                @media (max-width: 425px) {
                  /* margin: 1rem auto 0 auto; */
                  font-size: 1.25rem;
                  width: 90vw;
                  min-width: 100px;
                }
              `}
              id={id}
            >
              {text}
            </h1>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(HexTransition);
