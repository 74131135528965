import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import {
  QuickLink,
  DownArrow,
  UpArrow,
  quickLinkCss,
} from "./styled-components";

const LinkBar = styled("div")`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  background-color: #215732;
`;

const MobileQuickNav = styled("div")`
  width: 100%;
  overflow: hidden;
  background-color: #215732;
  transition: all 0.5s ease;
  display: none;
  position: absolute;
  top: 75;
  z-index: 10;
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: ${(props) => (props.open ? "fit-content" : 0)};
  }
`;

const LinkWrapper = styled("div")`
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1000px;
  min-width: 750px;
  @media (max-width: 1024px) {
    width: 90vw;
    min-width: 300px;
  }
`;

const QuickLinks = ({ pathArray }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);

  const handleWindow = (event) => {
    setWindowWidth(event.currentTarget.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleWindow);
    return () => {
      window.removeEventListener("resize", handleWindow);
    };
  }, []);

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <LinkBar>
        <LinkWrapper>
          {windowWidth > 960 &&
            pathArray.map((path, index) => (
              <AnchorLink
                css={quickLinkCss}
                key={index}
                to={`${path.path}`}
                title={`${path.title}`}
              />
            ))}
          {windowWidth <= 960 && !mobileOpen && (
            <DownArrow onClick={() => setMobileOpen(!mobileOpen)} />
          )}
          {windowWidth <= 960 && mobileOpen && (
            <UpArrow onClick={() => setMobileOpen(!mobileOpen)} />
          )}
        </LinkWrapper>
      </LinkBar>
      <MobileQuickNav open={mobileOpen}>
        {pathArray.map((path, index) => (
          <QuickLink key={index} to={`${path.path}`} title={`${path.title}`} />
        ))}
      </MobileQuickNav>
    </div>
  );
};

export default React.memo(QuickLinks, (prev, next) => {
  return prev.pathArray.length === next.pathArray.length;
});
